<template>
  <div>
    <base-dialog :show='!!error' title='An error ocurred!' @close='handError'>
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog :show='isLoading' title='Authenticating...'>
      <base-spinner></base-spinner>
    </base-dialog>
    <form @submit.prevent='submitForm'>
      <base-card>
        <div class='form-control'>
          <label for='email'>E-mail</label>
          <input type='email' id='email' v-model.trim='email'/>
        </div>
        <div class='form-control'>
          <label for='password'>Password</label>
          <input type='password' id='password' v-model.trim='password'/>
        </div>
        <p v-if='!formIsValid'>
          Please enter valid email and password (must be at least 6 characters long).
        </p>
        <div class='actions'>
          <base-button type='button' mode='flat' @click='switchAuthMode'>
            {{ switchModeButtonCaption }}
          </base-button>
          <base-button>
            {{ submitButtonCaption }}
          </base-button>
        </div>
      </base-card>
    </form>
  </div>
</template>

<script>
export default {
  name: 'UserAuth',
  data() {
    return {
      email: '',
      password: '',
      formIsValid: true,
      mode: 'login',
      isLoading: false,
      error: false,
    }
  },
  computed: {
    submitButtonCaption() {
      if (this.mode === 'login') {
        return 'Login';
      } else {
        return 'Signup';
      }
    },
    switchModeButtonCaption() {
      if (this.mode === 'login') {
        return 'Signup instead';
      } else {
        return 'Login';
      }
    }
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === '' ||
        !this.email.includes('@') ||
        this.password.length <= 6
      ) {
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;
      const actionPayload = {
        email: this.email,
        password: this.password
      };
      try {
        if (this.mode === 'login') {
          await this.$store.dispatch('login', actionPayload);
        } else {
          await this.$store.dispatch('singup', actionPayload)
        }
        const redirectUrl = '/' + (this.$route.query.redirect || 'coaches');
        this.$router.replace(redirectUrl);
      } catch (err) {
        this.error = err.message || 'Failed to authenticate, try later';
      }

      this.isLoading = false;
    },
    switchAuthMode() {
      if (this.mode === 'login') {
        this.mode = 'singup';
      } else {
        this.mode = 'login';
      }
    },
    handError() {
      this.error = null;
    }
  }
};
</script>

<style scoped>
form {
  margin: 1rem;
  border-radius: 12px;
}

.form-control {
  margin: .5rem 0 1.5rem;
}

label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
}

input,
textarea {
  transition: .3s;
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: .75rem;
  min-width: 100%;
  max-width: 100%;
}

input:focus,
textarea:focus {
  background-color: #f6f6f7;
  outline: none;
  border-color: #3d008d;
}

.errors {
  font-weight: bold;
  color: red;
}

.actions {
  text-align: right;
}
</style>
